import   React  from "react"
import { Link } from "gatsby"

import UPC  from "../../images/Icons/UPC,exigete,innova.svg"
import WASC from "../../images/Icons/WASC.svg"

const fb = 'https://www.facebook.com/upccultural1/'
const is = 'https://www.instagram.com/upccultural/'
const yt = 'https://www.youtube.com/@upccultural'


export default () => (
  <>
    <footer class="footer">
      <div class="inner">
        <div class="top">
          <nav>
            <Link to="/nosotros">Sobre UPC Cultural</Link>
            {/*<a>Quieres publicar tu evento?</a>*/}
            <Link to="/alianzas">Alianzas</Link>
          </nav>
          <div class="social">
            <p>Síguenos en Redes sociales</p>
            <a href={ fb } target="_blank" class="fa fa-facebook"></a>
            <a href={ is } target="_blank" class="fa fa-instagram"></a>
            <a href={ yt } target="_blank" class="fa fa-youtube-play"></a>
          </div>
        </div>
        <div class="bottom">
          <img src={ WASC } />
          <img src={ UPC  } />
        </div>
      </div>
    </footer>
  </>
)
